import {
  Timestamp,
  arrayUnion,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import {httpsCallable} from 'firebase/functions';
import {
  FirestoreCollection,
  HubblGroupSuggestion,
  InterestStatisticHubbl,
  Keys,
  NotificationDataType,
  Palette,
  createBreakId,
  createHubblAdminMockUser,
  createInitialSystemMessage,
  getRandomPrimaryColor,
} from 'hubbl-shared';
import {EventDTO} from '../models/event/EventDTO';
import {HubblGroupDTO} from '../models/hubbl/HubblGroupDTO';
import {firebaseFunctions, firestore} from './firebase';
import {mapUserToUserDTO} from './utils/map-incoming-users/mapUserToUserDTO';
import {InterestStatisticDTO} from '../models';
import {mapInterestStatisticDTOToInterestStatistic} from './utils/map-incoming-interest-statistic/mapInterestStatisticDTOToInterestStatistic';
import {mapInterestStatisticToInterestStatisticDTO} from './utils/map-incoming-interest-statistic/mapInterestStatisticToInterestStatisticDTO';

const addMondayNotification = async () => {
  const promise = new Promise<void>(async (resolve, reject) => {
    try {
      const functionsPersistStoreRef = doc(
        firestore,
        FirestoreCollection.FunctionsPersistStore,
        Keys.defaultFunctionsPersistStoreId,
      );

      await updateDoc(functionsPersistStoreRef, {
        'notificationsList.mondayNudge': arrayUnion({
          id: 'exploreColleagueHubbles',
          body: {
            da: 'Åben Hubbl og se, hvad der sker på din arbejdsplads! 🥳',
            en: 'Open Hubbl and explore what happens at your workplace 🥳',
          },
          title: {
            da: 'Så det mandag! 😎',
            en: 'Monday it is! 😎',
          },
        }),
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
  return promise;
};

const addEvent = async (companyId: string, officeId: string) => {
  const promise = new Promise<void>(async (resolve, reject) => {
    const event: EventDTO = {
      isRepeatedHubbl: false,
      attendees: [],
      companyId,
      creatorId: null,
      coverImageUrl: 'companies/aarhuskommune/event_pictures/fredagsbar.png',
      created: Timestamp.fromDate(new Date()),
      deadline: null,
      endDateTime: null,
      eventDescription: {
        da: 'Slut arbejdsugen af med stil! Deltag i vores fredagsbar og nyd en afslappet aften med øl og muligheden for at styrke båndene med dine fantastiske kollegaer. Start weekenden med godt selskab! 🎉🍻',
        en: 'End the workweek in style! Join our Friday bar and enjoy a relaxed evening with beers and the opportunity to strengthen bonds with your fantastic colleagues. Start the weekend with good company! 🎉🍻',
      },
      eventEmoji: '🎉',
      eventLocation: null,
      eventTags: [
        {emoji: '🍻', id: 'beers', label: {da: 'Øl', en: 'Beers'}},
        {emoji: '🎵', id: 'music', label: {da: 'Musik', en: 'Music'}},
        {emoji: '🍹', id: 'drinks', label: {da: 'Drinks', en: 'Drinks'}},
      ],
      eventTitle: {
        da: 'Fredagsbar!',
        en: 'Friday bar!',
      },
      id: 'fridaybar',
      isRepeatEnabled: true,
      marketingStartDate: Timestamp.fromDate(new Date()),
      messages: [createInitialSystemMessage()],
      officeId: officeId,
      startDateTime: Timestamp.fromDate(new Date()),
      filters: null,
      declinedUserIds: [],
    };
    try {
      const companyRef = doc(
        firestore,
        FirestoreCollection.Companies,
        companyId,
      );

      const currentTime = new Date();

      const eventsRef = doc(
        companyRef,
        FirestoreCollection.Events,
        event.id + currentTime.getTime(),
      );

      await setDoc(eventsRef, event);

      resolve();
    } catch (error) {
      reject(error);
    }
  });
  return promise;
};

const testScheduler = async () => {
  const callableFunc = httpsCallable(
    firebaseFunctions,
    //TODO: Add to constant in shared
    'onTestScheduler',
  );
  return callableFunc()
    .then(result => {
      console.log(result.data);
      const data = result.data;
      return data;
    })
    .catch(error => {
      console.log(`error: ${JSON.stringify(error)}`);
      throw error;
    });
};

const onMoveAdminToCompany = async (
  companyId: string,
  deleteOldUser: boolean,
  userId: string,
) => {
  const callableFunc = httpsCallable(
    firebaseFunctions,
    //TODO: Add to constant in shared
    'onMoveAdminToCompany',
  );
  return callableFunc({
    adminId: userId,
    newCompanyId: companyId,
    deleteAdminFromExstingCompany: deleteOldUser,
  })
    .then(result => {
      console.log(result.data);
      const data = result.data;
      return data;
    })
    .catch(error => {
      console.log(`error: ${JSON.stringify(error)}`);
      throw error;
    });
};

const sendNotificationToCompany = async (
  companyId: string,
  notificationTitle: string,
  notificationDescription: string,
  notificationData: NotificationDataType,
  userIds?: string[],
) => {
  const callableFunc = httpsCallable(
    firebaseFunctions,
    //TODO: Add to constant in shared
    'sendNotificationToCompany',
  );
  return callableFunc({
    companyId,
    notificationTitle,
    notificationDescription,
    notificationData,
    userIds,
  })
    .then(result => {
      console.log(result.data);
      const data = result.data;
      return data;
    })
    .catch(error => {
      console.log(`error: ${JSON.stringify(error)}`);
      throw error;
    });
};

interface AddHubblGroupSuggestionProps {
  companyId: string;
  description: string;
  location: string | null;
  emoji: string;
  /**
   * Use format: 2024-03-07T12:00:00.000
   */
  startDateTime: string;
  endDateTime?: string;
}

const addHubblGroupSuggestion = async ({
  companyId,
  description,
  emoji,
  endDateTime,
  location,
  startDateTime,
}: AddHubblGroupSuggestionProps) => {
  if (!companyId || companyId === '') {
    return false;
  }
  const promise = new Promise<void>(async (resolve, reject) => {
    const hubblAdmin = createHubblAdminMockUser({companyId});

    console.log('🚀🚀🚀🚀 ~ promise ~ hubblAdmin:', hubblAdmin);
    const hubblGroup: HubblGroupDTO = {
      isRepeatedHubbl: false,
      attendees: [],
      companyId,
      endDateTime: endDateTime
        ? Timestamp.fromDate(new Date(endDateTime))
        : null,
      description: {
        da: description,
        en: description,
      },
      emoji: emoji,
      location: location,
      id: createBreakId({userId: hubblAdmin.id, breakCreated: new Date()}),
      isRepeatEnabled: false, //SHOULD NOT BE TRUE
      messages: [createInitialSystemMessage()],
      officeId: Keys.defaultOffice,
      startDateTime: Timestamp.fromDate(new Date(startDateTime)),
      filters: null,
      attendeeIds: [],
      color: getRandomPrimaryColor() ?? Palette.primaryCherokee,
      createdDateTime: Timestamp.fromDate(new Date()),
      isGroupFilled: false,
      isHubblPassed: false,
      labels: [],
      maxAttendees: null,
      spaceId: Keys.defaultSpace,
      type: 'group',
      creator: mapUserToUserDTO(hubblAdmin),
      extraDetails: null,
      declinedUserIds: [],
    };
    try {
      const companyRef = doc(
        firestore,
        FirestoreCollection.Companies,
        companyId,
      );

      const hubblRef = doc(
        companyRef,
        FirestoreCollection.Hubbles,
        hubblGroup.id,
      );

      await setDoc(hubblRef, hubblGroup);

      resolve();
    } catch (error) {
      reject(error);
    }
  });
  return promise;
};

const getInterestStatisticById = async (
  companyId: string,
  interestStatisticId: string,
): Promise<InterestStatisticHubbl | null> => {
  const interestStatisticPromise = new Promise<InterestStatisticHubbl | null>(
    async (resolve, reject) => {
      try {
        const companyRef = doc(
          firestore,
          FirestoreCollection.Companies,
          companyId,
        );
        const interestStatisticRef = doc(
          companyRef,
          FirestoreCollection.InterestStatistic,
          interestStatisticId,
        );

        const interestStatisticSnapshot = await getDoc(interestStatisticRef);

        if (interestStatisticSnapshot.exists()) {
          resolve(
            mapInterestStatisticDTOToInterestStatistic(
              interestStatisticSnapshot.data() as InterestStatisticDTO,
            ),
          );
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(error);
      }
    },
  );
  return interestStatisticPromise;
};

const addInterestStatistic = async (companyId: string) => {
  const promise = new Promise<void>(async (resolve, reject) => {
    const interestStatistic: InterestStatisticDTO = {
      isEnabled: false,
      createdDate: new Date().toISOString(),
      designVariation: 1,
      id: new Date().getTime().toString(),
      interest: {
        emoji: '⛰️',
        id: 'interestId',
        label: {
          da: 'da',
          en: 'en',
        },
      },
      messages: [createInitialSystemMessage()],
      seenBy: [],
      usersWithInterest: [],
      companyId,
    };
    try {
      const companyRef = doc(
        firestore,
        FirestoreCollection.Companies,
        companyId,
      );

      const interestStatisticRef = doc(
        companyRef,
        FirestoreCollection.InterestStatistic,
        interestStatistic.id,
      );

      await setDoc(interestStatisticRef, interestStatistic);

      resolve();
    } catch (error) {
      reject(error);
    }
  });
  return promise;
};
const updateInterestStatistic = async (
  companyId: string,
  interestStatistic: InterestStatisticHubbl,
) => {
  const promise = new Promise<void>(async (resolve, reject) => {
    try {
      const companyRef = doc(
        firestore,
        FirestoreCollection.Companies,
        companyId,
      );

      const interestStatisticRef = doc(
        companyRef,
        FirestoreCollection.InterestStatistic,
        interestStatistic.id,
      );

      const interestStatisticDTO =
        mapInterestStatisticToInterestStatisticDTO(interestStatistic);

      await setDoc(interestStatisticRef, interestStatisticDTO);

      resolve();
    } catch (error) {
      reject(error);
    }
  });
  return promise;
};

const addGeneralHubblSuggestion = async (
  hubblSuggest: HubblGroupSuggestion,
) => {
  const promise = new Promise<void>(async (resolve, reject) => {
    try {
      const functionsPersistStoreRef = doc(
        firestore,
        FirestoreCollection.FunctionsPersistStore,
        Keys.defaultFunctionsPersistStoreId,
      );

      await updateDoc(functionsPersistStoreRef, {
        hubblGroupSuggestions: arrayUnion(hubblSuggest),
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
  return promise;
};

export const superAdmin = {
  addMondayNotification,
  addEvent,
  testScheduler,
  sendNotificationToCompany,
  addHubblGroupSuggestion,
  onMoveAdminToCompany,
  getInterestStatisticById,
  addInterestStatistic,
  updateInterestStatistic,
  addGeneralHubblSuggestion,
};
